import React from "react";
import Layout from "../components/layout";
import "../styles/policy.css";
import SEO from "../components/seo";

export default function userAgreement(props) {

    return (
        <Layout location={props.location} >
            <SEO title="Общие положения Пользовательского соглашения" />
            <section className="policy">
                <div className="container">
                    <p><b>Настоящее Пользовательское соглашение регулирует отношения между Администрацией
                        Цифровой платформы «Стрекоза» (далее также «Администрация») и пользователем сети
                        Интернет (далее также «Пользователь»), возникающие при использовании «Цифровой
                        платформы «Стрекоза»
                    </b></p>
                    <p><b>1. Общие положения Пользовательского соглашения</b></p>
                    <p><b>1.1. В настоящем документе и вытекающих или связанных с ним отношениях сторон
                        применяются следующие термины и определения:</b></p>
                    <div>
                        <p><b>Цифровая платформа «Стрекоза» (Платформа)</b> - программно-аппаратные средства
                            Администрации, размещенные в доменах <a href="http://strekoza.ai" rel="noopener noreferrer">http://strekoza.ai</a>,
                            <a href="http://deliverycrm.ru" target="_blank" rel="noopener noreferrer"> http://deliverycrm.ru</a>,
                            <a href="https://xn----gtbcnlbesfjqhefv9jm.xn--p1ai/" target="_blank" rel="noopener noreferrer"> отследить-посылку.рф</a> и их
                            поддоменах.</p>
                        <p><b>Пользователь</b> - дееспособное физическое или юридическое лицо, присоединившееся к настоящему
                            Соглашению в собственном интересе либо выступающее от имени и в интересах представляемого им
                            юридического лица.</p>
                        <p><b>«Сообщение»</b> -  SMS-сообщение, Voice-сообщение, MMS-сообщение, E-mail-сообщение, Viber-сообщение, сообщение в соц. Сети, формируемое самостоятельно Пользователем без участия
                            Администрации В качестве автора и отправителя любых сообщений выступает Пользователь.
                        </p>
                        <p><b>«SMS-сообщение»</b>- короткое текстовое сообщение (до 160 символов в латинице и до 70 символов в
                            кириллице), которое может быть отправлено и принято Мобильным Терминалом Абонента.
                        </p>
                        <p><b>«Voice-сообщение»</b> - голосовое сообщение, приходящее на телефон Абонента в виде входящего
                            звонка.</p>
                        <p><b>«MMS-сообщение»</b> - мультимедийное сообщение с вложением до 300 Кб, которое может быть
                            отправлено и принято Мобильным Терминалом Абонента. «E-mail-сообщение» - сообщение,
                            отправляемое на электронную почту адресата. «Viber-сообщение» - сообщение, отправляемое
                            Абоненту через приложение Viber от зарегистрированного имени. Порядок регистрации
                            устанавливается компанией Viber Media.
                        </p>
                        <p><b>«Сообщения в соц. Сети»</b> - Текстовые и/или мультимедийные сообщения, отправляемые абонентам
                            в личные сообщения в социальных сетях "Вконтакте", "Одноклассники", "Whatsapp" и другие.</p>
                        <p><b>«Абонент»</b> - физическое или юридическое лицо, пользующееся услугами подвижной
                            радиотелефонной связи одного из Операторов связи.
                        </p>
                        <p><b>«Оператор связи»</b> - юридическое лицо, предоставляющее услуги подвижной радиотелефонной
                            связи.</p>
                        <p><b>«Соглашение»</b> - настоящее соглашение со всеми дополнениями и изменениями.</p>
                        <p><b>«Учетные данные»</b> - уникальный логин (адрес электронной почты) и пароль, создаваемые
                            Пользователем в процессе Регистрации в Платформе либо измененные в дальнейшем Пользователем
                            через Личный кабинет после авторизации в Платформе.
                        </p>
                        <p><b>«Личный кабинет»</b> - персональный раздел Пользователя в Платформе, связанный с учетной
                            записью Пользователя, в котором Пользователю доступно управление отдельными функциональными
                            возможностями Платформы.</p>
                        <p><b>«Регистрация»</b> - совокупность действий Пользователя в соответствии с инструкциями, изложенными
                            Администрацией.</p>
                        <p><b>«Администрация»</b> - - ООО «ОТСЛЕДИТЬ-ПОСЫЛКУ». <br/>
                            Реквизиты Администрации указаны в настоящем Соглашении в разделе «Реквизиты»
                        </p>
                    </div>
                    <p>1.2. Использование Пользователем Платформы любым способом и в любой форме в пределах его
                        объявленных функциональных возможностей, включая:  </p>
                    <p>- просмотр размещенных в Платформе материалов, </p>
                    <p>- регистрация и/или авторизация в Платформе, </p>
                    <p>- передача в систему трек-номеров любым способом, </p>
                    <p>- размещение или отображение в Платформе любых материалов, включая, но не ограничиваясь
                        такими как: тексты, гипертекстовые ссылки, изображения, аудио и видеофайлы, сведения и/или иная
                        информация, создает договор на условиях настоящего Соглашения в соответствии с положениями ст.
                        435 Гражданского кодекса Российской Федерации.
                    </p>

                    <p>Независимо от факта регистрации и/или авторизации Пользователя в Платформе, использование
                        Платформы означает согласие Пользователя с настоящим Пользовательским соглашением и принятие
                        на себя обязательств следовать инструкциям по использованию Платформы, а также ответственности
                        за действия, связанные с использованием Платформы. При несогласии Пользователя со всеми или
                        некоторыми условиями настоящего пользовательского соглашения он обязан прекратить
                        использование Платформы, в ином случае считается, что все условия его полностью устраивают.
                        Администратор вправе вносить в настоящее пользовательское соглашение любые изменения и
                        дополнения по своему усмотрению, а также исключать из него отдельные положения, а Пользователь
                        обязан самостоятельно отслеживать актуальную редакцию. </p>


                    <p>1.3. Воспользовавшись любой из указанных выше возможностей по использованию Платформы,
                        Пользователь подтверждает, что:</p>
                    <p>- ознакомился с условиями настоящего Соглашения в полном объеме до начала использования
                        Платформы; </p>
                    <p>- принимает все условия настоящего Соглашения в полном объеме без каких-либо изъятий и
                        ограничений с вашей стороны и обязуетесь их соблюдать или прекратить использование Платформы.
                        Если Пользователь не согласны с условиями настоящего Соглашения или не имеете права на
                        заключение договора на их основе, Пользователю следует незамедлительно прекратить любое
                        использование Платформы; </p>
                    <p>- соглашение (в том числе любая из его частей) может быть изменено Администрацией без
                        какого-либо специального уведомления. Новая редакция Соглашения вступает в силу с момента ее
                        размещения в Платформе либо доведения до сведения Пользователя в иной удобной форме, если иное
                        не предусмотрено новой редакцией Соглашения.</p>
                    <p><b>2. Общие условия пользования Платформой</b></p>
                    <p>2.1. Администрация предлагает Пользователю на условиях настоящего Пользовательского соглашения
                        воспользоваться функциональными возможностями Платформы, позволяющими с помощью графического
                        интерфейса произвести отслеживание местонахождения товаров, приобретенных в Интернет-магазинах,
                        в процессе их доставки почтовыми службами покупателям-клиентам Интернет-магазинов в виде
                        рассылки SMS-оповещений на мобильные телефоны и/или электронную почту и/или Viber и/или
                        голосовых сообщений (звонки на мобильные или стационарные телефоны) и/или рассылки сообщений в
                        социальной сети «ВКонтакте», «Одноклассники» и других социальных сетях.
                        (прием/обработка/сортировка/прибытие/истечение срока хранения) им, а также произвести оповещение
                        самого Пользователя. Администрация может при помощи используемой ею Платформы уведомлять покупателей-клиентов Интернет-магазинов и Пользователя о доставленных и
                        ожидающих вручения (не врученных) посылках. Цифровая платформа не собирает и не хранит
                        персональные данные, а также иную информацию, все сведения загружаются Пользователем и доступны
                        для использования ими только в течение сеанса работы на платформе.

                    </p>
                    <p>
                        2.2. В платформе может быть настроена рассылка оповещений исключительно по информации, полученной от служб доставки.
                    </p>
                    <p>2.3. В отношении использования отдельных сервисов Администрацией могут быть установлены
                        дополнительные условия, правила и ограничения. Администрация вправе в любое время
                        пересматривать или изменять условия предоставления , дополнять, изменять, ограничивать,
                        расширять функциональные возможности Платформы, в том числе условия доступа Пользователя к
                        сервисам или отдельным функциональным возможностям Платформы.
                    </p>
                    <p>2.4. По требованию Пользователя предоставление функциональных возможностей Платформы может
                        регулироваться индивидуальным договором, составленным в письменной форме и подписанным
                        Администрацией и Пользователем. В случае противоречия или несоответствия между текстом
                        настоящего Пользовательского соглашения и индивидуальным договором применению подлежат положения
                        такого договора.
                    </p>
                    <p>2.5. Тарифы на использование Платформы размещены по адресу: <a
                      href="https://deliverycrm.ru/tariffs.html" target="_blank" rel="noopener noreferrer">https://deliverycrm.ru/tariffs.html</a>.
                    </p>
                    <p>2.6 Администрация вправе в любое время проводить изменения, дополнения, обновления и
                        улучшения сервисов Платформы, Изменения графического дизайна и функциональны возможностей
                        Платформы без предварительного уведомления Пользователя, в связи с чем использование
                        Платформы предлагается в режиме «как есть», т.е. том виде и объеме, в каком они предоставляются в
                        момент обращения к сервисам Пользователей.</p>
                    <p>2.7. Использование функциональных возможностей Платформы допускается только после
                        прохождения Пользователем регистрации и авторизации в Платформе в соответствии с
                        установленной Администрацией процедурой.
                    </p>
                    <p>2.8. При регистрации в Платформе Пользователь указывает Учетные данные, выбираемые им
                        самостоятельно. После внесения данных для Регистрации Пользователь получает электронное
                        письмо на адрес электронной почты, указанный при Регистрации, содержащее активную
                        гиперссылку, переход по которой необходим для подтверждения Регистрации. Регистрация учетной
                        записи осуществляется на один адрес электронной почты Пользователя однократно. Повторная
                        Регистрация новой учетной записи в Платформе с использованием ранее указанного при Регистрации
                        адреса электронной почты возможна при обращении в Администрацию. Пользователь может
                        изменить Учетные данные в Личном кабинете Платформы.</p>
                    <p>2.9. Пользователь обязуется самостоятельно следить за сохранностью своих Учетных данных и не
                        раскрывать их третьим лицам. Пользователь не вправе передавать свои Учетные данные третьим
                        лицам, а также прямо или косвенно разрешать третьим лицам использовать его Учетные данные для
                        авторизации в Платформе, за исключением лиц, действующих от имени и в интересах Пользователя
                        или получивших такие Учетные данные на основании соответствующих соглашений с
                        Пользователем. Любое действие, совершенное из Личного кабинета Пользователя с использованием
                        его Учетных данных считается действием, совершенным самим Пользователем или уполномоченным
                        им лицом, и устанавливает обязанности и ответственность для Пользователя в отношении таких
                        действий.
                    </p>
                    <p>2.10. Администрация вправе в любое время использовать полученную информацию от Пользователя
                        для сбора метрик клиентов Пользователя (включая РСЯ и подписку на push-уведомления) с целью
                        улучшения оказания услуг.</p>
                    <p>2.11. Администрация вправе в любое время проводить опросы клиентов Пользователя, а также
                        взаимодействовать с клиентами Пользователя.
                    </p>
                    <p><b>3. Лицензия на использование Платформы и ее допустимое использование </b></p>
                    <p>3.1. Администрация предоставляет зарегистрированному и/или авторизованному Пользователю право
                        безвозмездного функционального использования Платформы на условиях простой
                        (неисключительной) лицензии в пределах функциональных возможностей.
                    </p>
                    <p>3.2. Пользователь обязуется действовать исключительно в соответствии с действующим
                        законодательством и Пользовательским соглашением, а также нести в соответствии с
                        законодательством Российской Федерации полную ответственность за собственные действия и
                        бездействие при использовании Платформы.
                    </p>
                    <p>3.3. Доступные Пользователю сервисы Плафтормы могут быть использованы исключительно в целях,
                        для которых сервисы предназначены Администрацией. Использование Платформы иными способами
                        строго запрещены.
                    </p>
                    <p><b>4. Гарантии Пользователя</b></p>
                    <p>Принимая условия настоящего Соглашения, Пользователь подтверждаете и гарантируете, что:</p>
                    <p>- обладает всеми необходимыми правами и полномочиями для заключения договора на
                        использование Платформы;
                    </p>
                    <p>- обладает всеми необходимыми правами и полномочиями и гарантируете законность полученных
                        от третьих лиц персональных данных;
                    </p>
                    <p>- использование Платформы будет осуществляться исключительно для целей, разрешенных
                        настоящим Соглашением с соблюдением его положений, а равно требований российского
                        законодательства и общепринятой практики;
                    </p>
                    <p>- Пользователь не будет совершать каких-либо действий, которые вступают в противоречие или
                        препятствуют предоставлению Платформы или работе соответствующего оборудования, сетей, или
                        программного обеспечения, с помощью которых предоставляется Платформа;
                    </p>
                    <p>- использование Платформы для конкретных целей не нарушает имущественных и/или личных
                        неимущественных прав третьих лиц, а равно запретов и ограничений, установленных применимым
                        правом, включая без ограничения: авторские и смежные права, права на товарные знаки, знаки
                        обслуживания и наименования мест происхождения товаров, права на промышленные образцы, права
                        на использование изображений людей, предоставленные вами материалы не содержат информации
                        и/или образов, оскорбляющих честь, достоинство и деловую репутацию лиц, пропагандирующих
                        насилие, порнографию, наркотики, расовую или национальную вражду, и вами получены все
                        необходимые разрешения от уполномоченных лиц в связи с использованием таких материалов.
                    </p>
                    <p><b>5. Лицензия на использование пользовательских материалов</b></p>
                    <p>5.1. Принимая условия настоящего Соглашения, Пользователь безвозмездно предоставляет
                        Администрации право использования (разрешение на использование) материалов, которые
                        добавляются Пользователем (размещаются или транслируются) в Платформе.
                    </p>
                    <p>5.2. Право и/или разрешение на использование материалов Пользователя предоставляются
                        Администрации одновременно с добавлением Пользователем таких материалов в Платформу на весь
                        срок действия исключительных прав на объекты интеллектуальной собственности или защиты
                        неимущественных прав на указанные материалы для использования на территории всех стран мира.</p>
                    <p>5.3. В рамках пользования Платформы Пользователь самостоятельно и добровольно принимает
                        решение о предоставлении Администрации указанных материалов (фамилия, имя, отчество,
                        псевдоним, название организации Пользователя и его клиентов, адрес электронной почты, номер
                        мобильного и/или стационарного телефона, форма сообщений Пользователя, направляемых клиентам
                        Пользователя, а также любая иная информация, предоставленная Пользователем) исключительно для
                        целей исполнения Пользовательского соглашения, а также настоящим заявляет о своем согласии на
                        обработку Администрацией материалов Пользователя, их передачу для обработки другим
                        Пользователям и/или третьим лицам, действующим по поручению Администрации, в том числе для
                        целей доставки сообщений клиентам Пользователя.</p>
                    <p>5.4. Вся информация, размещенная Пользователем в Платформе, размещается им для целей
                        информационного оповещения клиентов Пользователя в виде SMS уведомлений, E-mail уведомлений,
                        Viber уведомлений, голосовых уведомлений (звонки на мобильные или стационарные телефоны) или
                        уведомлений в социальной сети «ВКонтакте», «Одноклассники» или в других социальных сетях при
                        помощи Платформы. Администрация не вправе передавать полученную от Пользователя
                        информацию в иных целях, не соответствующих настоящему Пользовательскому соглашению.
                    </p>
                    <p>5.5. В рамках использования Платформы Пользователь обязуется предоставлять только достоверные
                        материалы и несет ответственность за предоставленную им информацию. Пользователь обязуется
                        своевременно актуализировать предоставляемые им материалы посредством их редактирования в
                        Платформе.
                    </p>
                    <p><b>6. Ограничения</b></p>
                    <p>6.1. Соглашаясь с условиями настоящего Соглашения, Пользователь Сервисов Сайта
                        понимает и признает, что: </p>
                    <p>- к отношениям сторон по предоставлению Платформы на безвозмездной основе не подлежат
                        применению положения законодательства о защите прав потребителей;</p>
                    <p>- Платформа предоставляется на условиях «как есть», в связи с чем Пользователю не
                        представляются какие-либо гарантии, что Платформа будет соответствовать требованиям и
                        ожиданиям Пользователя, услуги Платформы будут предоставляться непрерывно, быстро, надежно и
                        без ошибок, результаты, которые могут быть получены с использованием Платформы , будут точными
                        и надежными, качество какого-либо продукта, услуги, информации и пользовательских материалов,
                        полученных с использованием Платформы, будет соответствовать вашим ожиданиям, все ошибки в
                        программном обеспечении Платформы будут исправлены.
                    </p>
                    <p>- всю ответственность за содержание материалов и соответствие его требованиям применимого
                        права несет лицо, создавшее данные материалы и/или добавившее его в Платформу.
                    </p>
                    <p>- Администрация никак не связана с материалами, предоставленными и/или размещенными (в том
                        числе транслируемыми) Пользователем в Платформе, и не осуществляет проверку содержания,
                        подлинности и безопасности этих материалов либо их компонентов, а равно их соответствия
                        требованиям применимого права, и наличия у Пользователей необходимого объема прав на его
                        использование.</p>
                    <p>6.2. В рамках использования Платформы Пользователь понимает и признает, что ему запрещается
                        использование Платформу для:</p>
                    <p>- размещения и/или распространения контрафактных материалов;</p>
                    <p>- размещения и/или распространения материалов порнографического характера, а равно пропаганды
                        порнографии и детской эротики, и рекламы интимных услуг;</p>
                    <p>- размещения и/или распространения любой иной запрещенной информации, включая
                        материалы, экстремистского характера, а также направленных на ущемление прав и свобод человека
                        по признакам расовой и национальной принадлежности, вероисповедания, языка, и пола,
                        подстрекающие к совершению насильственных действий в отношении человека, либо к
                        бесчеловечному обращению с животными, призывающие к совершению иных противоправных
                        действий, в том числе разъясняющие порядок изготовления и применения оружия, наркотических
                        средств и их прекурсоров, и т.д.;
                    </p>
                    <p>- преимущественного или исключительного размещения ссылок на другие сайты;</p>
                    <p>- распространения рекламных материалов в личных сообщениях иным Пользователям и клиентам
                        Пользователя без получения их предварительного согласия на их получение (СПАМ);</p>
                    <p>- ограничения с помощью пароля или иным способом доступа к файлам, размещенным в Платформе
                        или транслируемым на него.</p>
                    <p>6.3. Администрация никак не связана с любой предоставляемой Пользователем информацией,
                        включая содержание сообщений и уведомлений, направляемых клиентам Пользователя, и не обязана
                        осуществлять проверку содержания, подлинности и безопасности такой информации либо ее
                        компонентов, а равно ее соответствие требованиям действующего законодательства и наличия у
                        Пользователей необходимого объема прав на ее распространение и/или использование.
                    </p>
                    <p>6.4. Пользователь обязуется в обязательном порядке получить от своего клиента или адресата
                        согласие на получение Сообщений, выраженного посредством совершения ими действий, однозначно
                        идентифицирующих этого абонента или адресата и позволяющих достоверно установить его
                        волеизъявление на получение Сообщений, в форме, которая может быть предъявлена Оператору
                        связи в качестве доказательства наличия такого согласия.</p>
                    <p>6.5. В случае обнаружения нарушения прав и/или интересов Пользователя в связи с предоставлением
                        услуг, в том числе незаконного размещения материалов иным Пользователем, об этом факте следует
                        сообщить Администрации. Для этого направить Администрации письменное уведомление с
                        подробным изложением обстоятельств нарушения и гипертекстовой ссылкой на страницу
                        Платформы, содержащую материалы, которыми нарушаются права и/или интересы Пользователя.
                    </p>
                    <p>6.6. В случае возникновения каких-либо претензий третьих лиц в отношении нарушения любых
                        имущественных и/или личных неимущественных прав третьих лиц, а равно установленных
                        законодательством запретов или ограничений Пользователь обязан по требованию Администрации
                        пройти официальную идентификацию, предоставив Администрации нотариально заверенное
                        обязательство урегулировать возникшие претензии собственными силами и за свой счет с указанием
                        своих паспортных данных.
                    </p>
                    <p>6.7. Администрация оставляет за собой право удалить любые материалы с Платформы либо временно
                        ограничить доступ к ним в одностороннем порядке без объяснения причин.</p>
                    <p>6.8. В случае многократного или грубого нарушения условий настоящего Соглашения и/или
                        требований законодательства Администрация оставляет за собой право заблокировать Учетные
                        данные (аккаунт) Пользователя целиком, удалить его либо иным образом ограничить (прекратить)
                        предоставление услуг.
                    </p>
                    <p>6.9. В случае привлечения Администрации к ответственности или наложения на нее взыскания в
                        связи с допущенными Пользователем нарушениями прав и/или интересов третьих лиц, а равно
                        установленных законодательством запретов или ограничений, Пользователь обязан в полном объеме
                        возместить убытки Администрации.
                    </p>
                    <p>6.10. В случае получения Администрацией претензий, связанных с отправкой сообщений
                        (уведомлений) Пользователя через Платформу , со стороны контролирующих органов, в том числе
                        предъявленных в связи с отсутствием согласия клиентов Пользователя
                        (абонентов) на получение сообщений, Пользователь признается непосредственным
                        рекламораспространителем и отправителем сообщений и самостоятельно несет ответственность за
                        свои действия в соответствии с законодательством Российской Федерации.</p>
                    <p>6.11. Администрация не несет ответственности за актуальность (правдивость) оповещений,
                        информация о которых предоставлена службами доставки не в полном объеме и/или некорректно.</p>
                    <p>6.12. Администрация не несет ответственности за персональные данные третьих лиц, которые были
                        предоставлены Пользователем Администрации, включая ответственности за законность получения
                        Пользователем персональных данных третьих лиц. </p>
                    <p>6.13. Пользователь уведомлен, что Администрация не является и не будет являться стороной
                        договорных отношений между Пользователем и клиентом Пользователя. Администрация не несет
                        никаких обязательств и ответственности перед клиентами Пользователя и/или третьими лицами,
                        аффилированными с Пользователем. Прямые обязательства Администрации перед Пользователем
                        указаны в предмете настоящего договора. В связи с этими обстоятельствами, Администрация не
                        имеет право принимать претензии от клиентов Пользователя и/или принимать участие в спорных
                        ситуациях с клиентами Пользователя и не может быть привлечен к ответственности в отношении к
                        клиентам Пользователя. </p>
                    <p><b>7. Уведомления</b></p>
                    <p>7.1. Пользователь соглашается получать от Администрации на указанный в Платформе электронный
                        адрес и/иди мобильный телефон информационные электронные сообщения e-mail и/или текстовые
                        сообщения SMS и/или Viber (далее также «нотификаторы») о важных событиях, происходящих в
                        рамках работы Платформы или в связи с ним, иные сообщения информационного и/или рекламного
                        характера.</p>
                    <p>7.2. Администрация вправе использовать нотификаторы для информирования Пользователя о
                        возможностях Платформы и/или об изменении в размещенных на них информационных ресурсах.
                    </p>
                    <p><b>8. Ответственность сторон</b></p>
                    <p>8.1. Стороны несут ответственность в соответствии с настоящим договором и действующим
                        законодательством Российской Федерации.
                    </p>
                    <p>8.2. Ответственность за содержание сообщений (уведомлений) и присваиваемое имя отправителя
                        несет Пользователь самостоятельно. В рамках пользования Платформой Администрация
                        обеспечивает отправку сообщений в их неизменном содержании и объеме, в связи с этим
                        ответственность за включение в сообщения (уведомления) сведений и данных, противоречащих
                        действующему законодательству, возлагается на Пользователя.
                    </p>
                    <p>8.3. При получении Администрацией информации о нарушении Пользователем настоящего соглашения
                        Администрация, при необходимости, приостанавливает доступ к платформе, уведомляет Пользователя и
                        проводит внутреннее расследование с привлечением Пользователя.</p>
                    <p>8.4. Формирование текста любых сообщений, рассылаемых с использованием любых каналов связи в
                        рамках настоящего договора, производит самостоятельно и под свою ответственность Пользователь
                        без участия Администрации, при этом Пользователь гарантирует соответствие таких сообщений
                        требованиям законодательства РФ, наличия согласия абонентов на их получение, наличие согласия
                        абонентов на обработку персональных данных и соблюдение в полном объеме всех иных без
                        исключения требований законодательства РФ. В случае привлечения Администрации к
                        ответственности или наложения на него взыскания со стороны абонентов, контролирующих органов и
                        иных без исключения лиц в связи с допущенными Пользователем нарушениями прав и/или интересов
                        третьих лиц, а равно установленных законодательством запретов или ограничений, Пользователь
                        обязан в полном объеме возместить Администрации любые суммы убытков, штрафов, компенсаций и
                        любых иных без исключения сумм независимо от их размера, вида, правовой природы и оснований
                        возникновения.</p>
                    <p>8.5. В случае получения Администрацией претензий, связанных с отправкой сообщений
                        (уведомлений) Пользователем через сервис, со стороны контролирующих органов, в том числе
                        предъявленных в связи с отсутствием согласия клиентов Пользователя (абонентов) на
                        получение сообщений, Пользователь признается непосредственным рекламораспространителем и
                        отправителем сообщений и самостоятельно несет ответственность за свои действия в соответствии с
                        законодательством Российской Федерации.</p>
                    <p>8.6. Администрация не несет ответственности за персональные данные третьих лиц, которые были
                        предоставлены Пользователем, включая ответственности за законность получения Пользователем
                        персональных данных третьих лиц.</p>
                    <p>8.7. Пользователь гарантирует наличие у него всех необходимых разрешений на передачу
                        Администрации любых без исключения данных, необходимых для заключения и исполнения
                        настоящего соглашения, и несет ответственность за соблюдение данного заверения, включая передачу
                        физическими лицами персональных данных. Пользователь принимает на себя обязательство
                        возместить Администрации любые суммы штрафов, наложенные на них по решению
                        уполномоченных государственных органов.</p>
                    <p>8.8. Пользователь обязан обеспечивать сохранность всех конфиденциальных сведений,
                        предоставляющих доступ к его учетной записи, и информировать Администрацию обо всех случаях
                        несанкционированного вмешательства. В ином случае все действия, совершаемые с использованием
                        учетной записи Пользователя, считаются совершенными им самим.
                    </p>
                    <p>8.9. Пользователь гарантирует, что он является добросовестным участником гражданского оборота
                        и в полном объеме выполняет все возложенные на него в соответствии с действующим налоговым
                        и иным законодательством РФ обязанности, включая своевременную уплату налогов и сборов, сдачу
                        налоговых деклараций и иной отчетности. Пользователь гарантирует использование услуг
                        Администрации в соответствии с законными целями.</p>
                    <p>8.10. Пользователь обязуется в полном объеме соблюдать все требования, связанные с
                        оригинальностью, качеством, безопасностью, состоянием и комплектностью товаров, отправляемых
                        потребителям, соответствие вложений посылок тем товарам, которые действительно были заказаны
                        потребителями, а также обязуется соблюдать иные требования, предусмотренные действующим
                        законодательством РФ. В случае предъявления каких-либо требований к Администрации в связи с
                        нарушением данного условия Пользователь обязан урегулировать конфликтную ситуацию своими
                        силами и за свой счет, а также возместить Исполнителю в полном объеме все причиненные ему
                        убытки в любом размере, включая суммы штрафов, компенсаций и иных сумм независимо от их
                        размера, правовой природы и оснований их возникновения. </p>
                    <p>8.11. Ответственность Администрации перед Заказчиком ограничена реальным ущербом в пределах
                        суммы 1 000 рублей. </p>
                    <p><b>9. Прочие условия</b></p>
                    <p>9.1. Настоящее Пользовательское соглашение, порядок его заключения и исполнения, а также
                        вопросы, не урегулированные настоящим соглашением, регулируется действующим
                        законодательством Российской Федерации.</p>
                    <p>9.2. Все споры, вытекающие из настоящего Пользовательского соглашения или возникающие в связи
                        с ним, подлежат рассмотрению в суде по месту нахождения Администрации в соответствии с
                        действующим законодательством Российской Федерации.</p>
                    <p>9.3. Настоящее Соглашение может быть изменено или прекращено Администрацией в одностороннем
                        порядке без предварительного уведомления Пользователя и без выплаты какой-либо компенсации в
                        связи с этим.</p>
                    <p>9.4. Признание отдельных частей настоящего Пользовательского соглашения недействительными не
                        отменяет действие других положений настоящего Пользовательского соглашения.
                    </p>
                    <p>9.5. Действующая редакция настоящего Соглашения размещена в Платформе.</p>
                    <h5 className="policy__head">ООО «Стрекоза»</h5>
                    <p>
                        129327, г. Москва, ул. Коминтерна, д. 20/2, этаж 1, <br/>
                        помещение/комната/ рабочее место 1/3/11а <br/>
                        ОГРН 1217700197934, ИНН 7716956287/КПП 771601001
                    </p>
                    <p className="policy__head-sub">Контакты для связи с Администрацией Сервиса</p>
                    <p> e-mail: <a href="mailto:support@r-lab.biz">support@r-lab.biz</a>, <a href="mailto:support@strekoza.ai">support@strekoza.ai</a></p>
                    <p>Дата публикации: 14.07.2021г.</p>
                </div>
            </section>
        </Layout>
    )

}

